
export enum ApiEndPoints {
  //login
  signIn = 'auth/login',
  signUp = 'auth/signUp',
  

  // users
  userget = 'profile/find',
  profileDetailsEdit = "profile/update",
  profileRewardsEdit = "profile/rewardsUpdate",
  profileGet = "profile/get",

  // Admin

  adminSignUp = 'auth/admin/signUp',
  adminLogin = 'auth/admin/login',

  // OTP

  sendOTP = 'auth/sendOTP',
  verifyOTP = 'auth/verifyOTP',


  // Loan

  requestLoan = 'loan/request',
  requestedLoans = 'loan/requestedLoans',
  getLoanDetails = 'loan/getDetails',
  
  // Forget 
  otpAuth = 'auth/forgotPasswordOtp',
  otpVerify = 'auth/forgotPasswordVerify',
  updatePassword = 'auth/updatePassword'
}
