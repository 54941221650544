import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { PeriodicElement } from 'src/app/modules/admin/admin.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  slug: string | null = null;
  user: any;
  isAdmin: boolean = false;
  items: PeriodicElement[] = [];
  dataSource: PeriodicElement[] = [];
  rewardPoint: any;
  constructor(private router: Router, private route: ActivatedRoute, private Apihelper: ApiHelper, public toastrService: ToastrService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // this.slug = params.get;
      // (params);
    })
    if (this.slug === 'admin') {
      this.isAdmin = false;
    }
    const userDetails = sessionStorage.getItem('profile');
    if (userDetails) {
      this.user = JSON.parse(userDetails);
    }
    this.userGet()
  }
  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }
  userGet() {
    this.Apihelper.post({}, ApiEndPoints.userget).subscribe(
      response => {
        this.items = response.data;
        this.dataSource = [...this.items]; // initialize dataSource
        const rewardCode = this.dataSource.map((e) => {
          if (this.user.referralCode === e.referralCode) {
            this.rewardPoint = e.rewards;
          }
        })
        
      },
      error => {
        this.toastrService.error('Error fetching user data.');
      }
    );
  }
}
