<div class="login-body">
  <div class="container">
    <div class="form-container sign-in">

      <form [formGroup]="loginForm" (ngSubmit)="onSignin()" *ngIf="signinSec">
        <h2 class="text-center">Sign In</h2>
        <input type="text" formControlName="phoneNumber" placeholder="Phone Number" />
        <input type="password" formControlName="password" placeholder="Password" />
        <a (click)="addPhonenum()">→ Forget Your Password? ←</a>
        <button type="submit">Sign In</button>
        <button [routerLink]="['/admin-login']">Admin Login</button>
      </form>

      <form *ngIf="phoneNumberAdd" [formGroup]="numberAdd" (ngSubmit)="phonenumSubmit()">
        <p>Phone Number</p>
        <input type="text"  formControlName="phoneNumber" placeholder="Enter your phone number" class="form-control"
          [ngClass]="{'is-invalid': numberAdd.get('phoneNumber')?.invalid && numberAdd.get('phoneNumber')?.touched}" >
        <div *ngIf="numberAdd.get('phoneNumber')?.invalid && numberAdd.get('phoneNumber')?.touched" class="text-danger">
          <small *ngIf="numberAdd.get('phoneNumber')?.errors?.['required']">Phone number is required.</small>
          <small *ngIf="numberAdd.get('phoneNumber')?.errors?.['pattern']">Please enter a valid 10-digit phone
            number.</small>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="numberAdd.invalid">Submit</button>
        <!-- Back Button -->
        <button type="button" class="btn btn-secondary" (click)="addPhonenum2()">Back</button>
      </form>

      <form *ngIf="otpForm" [formGroup]="otpNumber" (ngSubmit)="validateOtp()">
        <div class="p-2 text-center">
          <h4 class="text-danger">Please enter the one time password <br> to verify your account</h4>
          <div> <span>A code has been sent to</span> <small>{{maskedPhoneNumber}}</small> </div>
          <div class="inputs d-flex flex-row justify-content-center mt-2">
            <input class="me-2 text-center form-control rounded" type="text" formControlName="num1" (input)="moveToNext($event, 'num1')"  maxlength="1" /> 
            <input class="me-2 text-center form-control rounded" type="text" formControlName="num2" (input)="moveToNext($event, 'num2')"  maxlength="1" /> 
            <input class="me-2 text-center form-control rounded" type="text" formControlName="num3" (input)="moveToNext($event, 'num3')"  maxlength="1" />
            <input class="me-2 text-center form-control rounded" type="text" formControlName="num4" (input)="moveToNext($event, 'num4')"  maxlength="1" /> 
            <input class="me-2 text-center form-control rounded" type="text" formControlName="num5" (input)="moveToNext($event, 'num5')"  maxlength="1" />
            <input class="me-2 text-center form-control rounded" type="text" formControlName="num6" (input)="moveToNext($event, 'num6')"  maxlength="1" />
          </div>
          <div class="mt-4"> <button type="submit" class="btn btn-danger px-4 validate">Validate</button> </div>
        </div>
      </form>

      <form *ngIf="confirmPassword" [formGroup]="confirmpasswordForm" (ngSubmit)="confirmPassfunc()">
        <p class="p-0 m-0">New Password</p>
        <input type="text" class="form-control" formControlName="newPassword">
        <p class="p-0 m-0">Confirm Password</p>
        <input type="text" class="form-control" formControlName="confirmPassword">
        <button type="submit" class="btn btn-danger px-4">Submit</button>
      </form>
    </div>

    <div class="toggle-container">
      <div class="toggle">
        <div class="toggle-panel toggle-right">
          <h1>Hello, Friend!</h1>
          <p>Register with your personal details to use all of site features</p>
            <button class="hidden" [routerLink]="['/signin']">Sign Up</button>
            <button class="hidden" [routerLink]="['/home']">Home Page</button>
        </div>
      </div>
    </div>

     

  </div>
</div>