
	<!-- <div id="preloader"></div> -->
	<div class="up">
		<a href="#" class="scrollup text-center"><i class="fas fa-chevron-up"></i></a>
	</div>

<!-- Start of header section
	============================================= -->
	<header id="header_id" class="main_header header_style_four">
		<!-- <div class="header_top_content clearfix">
			<div class="container">
				<div class="header_top_text float-left">
					<span>Do you want to consult us? <a routerLink="contact">Find out more</a></span>
				</div>
				<div class="header_top_contact float-right">
					<a href="#"><i class="fas fa-phone"></i> 7403 111222</a>
					<a href="#"><i class="fas fa-map-marker-alt"></i> 27/12 J, 2nd Floor, A K S Tower ,  Calicut , Kerala </a>
					<a href="#"><i class="far fa-envelope"></i> info</a>
				</div>
			</div>
		</div> -->
		<div class="header_main_menu_wrap clearfix">
			<div class="container">
				<div class="brand_logo float-left">
					<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt=""></a>

				</div>
				<div class="rx_menu_item">
					<nav class="rx_main_navigation ul-li">
						<ul>
							<ul>
								<li><a class="nav-link" routerLink="/home">Home</a></li>
								<li><a class="nav-link" routerLink="/about">About</a></li>
								<li><a class="nav-link" routerLink="/service">Service</a></li>
								<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
								<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
								<li><a class="nav-link active" routerLink="/contact">Contact</a></li>
                            </ul>
						</ul>
					</nav>
					<div class="rx_header_btn text-center text-uppercase float-right">
						<a [routerLink]="['/loan-app']">Loan App</a>
					</div>
				</div>
				<!-- desktop menu -->
				<div class="rx-mobile_menu position-relative">
					<div class="rx-mobile_menu_button rx-open_mobile_menu" (click)="toggleClass()">
						<i class="fas fa-bars"></i>
					</div>
					<div class="rx-mobile_menu_wrap" [ngClass]="{'mobile_menu_on': isClassActive}">
						<div class="mobile_menu_overlay rx-open_mobile_menu"></div>
						<div class="rx-mobile_menu_content">
							<div class="rx-mobile_menu_close rx-open_mobile_menu" (click)="toggleClass()">
								<i class="fas fa-times"></i>
							</div>
							<div class="m-brand-logo text-center">
								<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt=""></a>
							</div>
							<nav class="rx-mobile-main-navigation  clearfix ul-li">
								<ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
									<li><a class="nav-link" routerLink="/home">Home</a></li>
							<li><a class="nav-link" routerLink="/about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link active" routerLink="/contact">Contact</a></li>
								</ul>
							</nav>
						</div>
					</div>
					<!-- /Mobile-Menu -->
				</div>
			</div>
		</div>
	</header>
<!-- End of header section
	============================================= -->

	<section class="saasio-breadcurmb-section">
		<div class="container">
			<div class="breadcurmb-title text-center">
				<h2>Contact Us</h2>
			</div>
			<div class="breadcurmb-item-list text-center ul-li">
				<ul class="saasio-page-breadcurmb">
					<li><a href="#">Home</a></li>
					<li><a href="#">Contact Us</a></li>
				</ul>
			</div>
		</div>
	</section>

	<div class="rx-section-title headline text-center">
		<div class="container">
			<h2 class="pt-5 ">Contact Us <span>Today!</span>  </h2>
			<p class="pb-5">Experience the Loan Onn difference. Get in touch with us today to learn more about our services and how we can assist you in achieving your financial aspirations. Remember, with Loan Onn, there are no fees, no hidden charges, just pure financial solutions designed for you.</p>
		</div>
	</div>

	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.0575546236687!2d75.79082502504897!3d11.257176538922309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65908e916c56f%3A0x850de3e751a908ef!2sINFINPLUS%20PVT%20LTD%20(infinsmartadvise)!5e0!3m2!1sen!2sin!4v1718736312505!5m2!1sen!2sin" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

<!-- Start of contact section
	============================================= -->		
	<section id="rx-contact" class="rx-contact-section">
		<div class="background_parallax background_position relative-position"  data-background="assets/images/img/company-transparency.jpeg">
			<div class="background_overlay"></div>
			<div class="container">
				<div class="rx-contact-content">
					<div class="row">
						<div class="col-lg-6">
							<div class="rx-contact-text">
								<div class="rx-contact-icon text-center">
									<i class="fas fa-phone"></i>
								</div>
								<div class="rx-contact-text-wrap headline ul-li-block">
									<h4>Free Consultion</h4>
									<ul>
										<li>
											<i class="fas fa-map-marker-alt"></i> 
											<a href="#">Loanonn &#8482;<br> Infin Plus Pvt Ltd <br> 27/12 J, 2nd Floor, A K S Tower Puthiyara Jn.,<br> Calicut Kerala – 673004</a>
										</li>
										<li>
											<i class="fas fa-envelope"></i><a href="#">info&#64;loanonn.com</a>
										</li>
										
										<li>
											<i class="fas fa-envelope mr-2"></i><a href="#">
												info&#64;investfinanceplus.com</a>
										</li>
										<li>
											<i class="fas fa-phone"></i><a href="#">7403 111222</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="rx-contact-form">
								<form action="#" method="POST">
									<input type="text" name="name" placeholder="Name">
									<input type="email" name="email" placeholder="Mail Address">
									<input type="phone" name="phone" placeholder="Phone Number">
									<textarea name="text" placeholder="Text..."></textarea>
									<button type="submit"><i class="fas fa-paper-plane"></i> Submit now</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
<!-- End of contact section
	============================================= -->
<!-- Start of Footer section
	============================================= -->
	<footer  class="footer_section relative-position footer_section_4">
		<div class="background_overlay"></div>
		<div class="container">
			<div class="footer_content">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								About Us
							</h3>
							<div class="widget_footer_text">
								At Loan Onn, we pride ourselves on being your one-stop destination for all loans.
							</div>
							
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								Usefull Link

							</h3>
							<div class="practice_list ul-li-block clearfix">
								<ul>
									<li><a class="nav-link active" routerLink="/home">Home</a></li>
							<li><a class="nav-link" routerLink="/about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								Contact Us
							</h3>
							<div >
								<ul>
									<li class="d-flex">
										<i class="fas fa-map-marker-alt mr-2"></i> 
										<a href="#"> Loanonn <br> Infin Plus Pvt Ltd <br>
										27/12 J, 2nd Floor, A K S Tower
										Puthiyara Jn, Calicut
										Kerala – 673004 </a>
									</li>
									<li >
										<i class="fas fa-envelope mr-2"></i><a href="#">
											info&#64;investfinanceplus.com</a>
									</li>
									<li >
										<i class="fas fa-envelope mr-2"></i><a href="#">
											info&#64;loanonn.com</a>
									</li>
									<li>
										<i class="fas fa-phone mr-2"></i><a href="#">
											7403 111222</a>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>

	<div class="footer_copyright">
		<div class="container">
			<div class="footer_copyright_content">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="footer_social ul-li clearfix">
							<ul>
								<li><a href="https://www.facebook.com/infinsmartadvise/"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href="#"><i class="fab fa-twitter"></i></a></li>
								<li><a href="https://www.instagram.com/infinsmartadvise/"><i class="fab fa-instagram"></i></a></li>
								
								
							</ul>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
							<div class="footer_logo text-center">
						<p class="text-white"><span>*</span>Terms & Conditions</p>
						<h6 class="text-white">The loans offered on the platform are subject to the terms and conditions and loan approval process of the  RBI-licensed BANKS AND NBFC partners</h6>
					</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="copyright_text text-right">
							Copyright By  <a href="https://hexabranding.com/">Hexa Branding</a> - 2024
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- End of Footer section
	============================================= -->
