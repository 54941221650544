import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  allData: any = [];
  btnVerify: boolean = true;
  items: PeriodicElement[] = [];
  displayedColumns: string[] = ['position', 'name', 'number', 'symbol'];
  dataSource: PeriodicElement[] = [];

  constructor(
    private Apihelper: ApiHelper,
    private router: Router,
    public toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.loanRequests();
    this.userGet();
  }

  veridy(i: number) {
    this.btnVerify = !this.btnVerify;
  }

  loanRequests() {
    this.Apihelper.post({}, ApiEndPoints.requestedLoans).subscribe(
      response => {
        this.allData = response.data;
      },
      error => {
        this.toastrService.error('Error fetching loan requests.');
      }
    );
  }

  userGet() {
    this.Apihelper.post({}, ApiEndPoints.userget).subscribe(
      response => {
        this.items = response.data;
        console.log(response);
        
        this.dataSource = [...this.items]; // initialize dataSource
      },
      
      error => {
        this.toastrService.error('Error fetching user data.');
      }
    );
  }

  updateRewards(item: PeriodicElement, index: number) {
    if (item.rewardsToUpdate && item.rewardsToUpdate.trim() !== '') {
      const currentRewards = isNaN(parseInt(item.rewards, 10)) ? 0 : parseInt(item.rewards, 10);
      const newRewards = parseInt(item.rewardsToUpdate.trim(), 10);
  
      if (!isNaN(newRewards)) {
        const updatedRewards = (currentRewards + newRewards).toString();
        const requestBody = {
          userId: item.id,
          updatedRewards: updatedRewards
        };
    
        
        this.Apihelper.post(requestBody, ApiEndPoints.profileRewardsEdit).subscribe(
          response => {
            if (response.apiStatus == 200) {
              this.dataSource[index].rewards = updatedRewards;
             
              this.toastrService.success(`Rewards updated for ${item.name}. New value: ${updatedRewards}`);
              item.rewardsToUpdate = ''; 
            } else {
              this.toastrService.error(`Failed to update rewards for ${item.name}.`);
            }
          },
          error => {
            this.toastrService.error('Error updating rewards. Please try again later.');
          }
        );
      } else {
        this.toastrService.error('Please enter a valid numeric rewards value.');
      }
    } else {
      this.toastrService.error('Please enter a rewards value.');
    }
  }
}  

export interface PeriodicElement {
  id: number;
  name: string;
  no: number;
  contact: number;
  rewards: string;
  referralCode: string;
  rewardsToUpdate?: string;
}
