<div class="container">
  <mat-tab-group dynamicHeight>
    <mat-tab label="All Users">
      <div class="container pt-4">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-striped table-height">
            <thead>
              <tr>
                <th>No</th>
                <th>ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Referal Code</th>
                <th>Rewards</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.contact }}</td>
                <td>{{ item.referralCode }}</td>
                <td>{{ item.rewards }}</td>
                <td>
                  <input type="text" class="form-control" value="10"  placeholder="" [(ngModel)]="item.rewardsToUpdate">
                </td>
                <td>
                  <button type="button" class="btn btn-primary" (click)="updateRewards(item, i)">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="no-data" *ngIf="items.length <= 0">
            <img src="assets/images/img/no-data.avif" alt="">
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Loan Requested">
      <div class="example-large-box mat-elevation-z4">
        <div class="container pt-4">
          <div class="table-responsive">
            <table class="table table-sm table-hover table-striped table-height">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Loan ID </th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Refer</th>
                  <th>Loan Type</th>
                  <th>Email</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.contact }}</td>
                  <td>{{ item.refer || '' }}</td>
                  <td>{{ item.loanType }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <a [href]="item.file" target="_blank" class="btn btn-border" [download]="item.file">Download</a>
                  </td>
                  <!-- <td>
                    <button type="button" class="btn btn-primary" [ngClass]="btnVerify?'btn-success':'btn-danger'"  (click)="veridy(i)"> Verify</button>
                  </td> -->
                </tr>
              </tbody>
            </table>

            <div class="no-data" *ngIf="allData.length <= 0">
              <img src="assets/images/img/no-data.avif" alt="">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  
</div>



