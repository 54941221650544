<!-- <div id="preloader"></div> -->
<div class="up">
	<a href="#" class="scrollup text-center"><i class="fas fa-chevron-up"></i></a>
</div>

<!-- Start of header section
	============================================= -->
<header id="header_id" class="main_header header_style_four">
	<!-- <div class="header_top_content clearfix">
			<div class="container">
				<div class="header_top_text float-left">
					<span>Do you want to consult us? <a routerLink="contact">Find out more</a></span>
				</div>
				<div class="header_top_contact float-right">
					<a href="#"><i class="fas fa-phone"></i> 7403 111222</a>
					<a href="#"><i class="fas fa-map-marker-alt"></i> 27/12 J, 2nd Floor, A K S Tower ,  Calicut , Kerala </a>
					<a href="#"><i class="far fa-envelope"></i> info</a>
				</div>
			</div>
		</div> -->
	<div class="header_main_menu_wrap clearfix">
		<div class="container">
			<div class="brand_logo float-left">
				<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt="logo"></a>
			</div>
			<div class="rx_menu_item">
				<nav class="rx_main_navigation ul-li">
					<ul>
						<li><a class="nav-link active" routerLink="/home">Home</a></li>
						<li><a class="nav-link" routerLink="/about">About</a></li>
						<li><a class="nav-link" routerLink="/service">Service</a></li>
						<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
						<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
						<li><a class="nav-link" routerLink="/contact">Contact</a></li>
					</ul>
				</nav>
				<div class="rx_header_btn text-center text-uppercase float-right">
					<a [routerLink]="['/login']">Loan App</a>
				</div>
			</div>
			<!-- desktop menu -->
			<div class="rx-mobile_menu position-relative">
				<div class="rx-mobile_menu_button rx-open_mobile_menu" (click)="toggleClass()">
					<i class="fas fa-bars"></i>
				</div>
				<div class="rx-mobile_menu_wrap" [ngClass]="{'mobile_menu_on': isClassActive}">
					<div class="mobile_menu_overlay rx-open_mobile_menu"></div>
					<div class="rx-mobile_menu_content">
						<div class="rx-mobile_menu_close rx-open_mobile_menu" (click)="toggleClass()">
							<i class="fas fa-times"></i>
						</div>
						<div class="m-brand-logo text-center">
							<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt=""></a>
						</div>
						<nav class="rx-mobile-main-navigation  clearfix ul-li">
							<ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
								<li><a class="nav-link active" routerLink="/home">Home</a></li>
								<li><a class="nav-link" routerLink="/about">About</a></li>
								<li><a class="nav-link" routerLink="/service">Service</a></li>
								<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
								<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
								<li><a class="nav-link" routerLink="/contact">Contact</a></li>
							</ul>
						</nav>
					</div>
				</div>
				<!-- /Mobile-Menu -->
			</div>
		</div>
	</div>
</header>
<!-- End of header section
	============================================= -->

<!-- Start of slider  section
	============================================= -->
<div class="container">

</div>
<section class="hero">
	<div class="hero__animation">
		<!-- <img decoding="async" src="assets/images/img/hero_vector_dollar.png" alt="Image"> -->
		<!-- <img decoding="async" src="assets/images/img/hero_vector_message.png" alt="Image"> -->
		<!-- <img decoding="async" src="assets/images/img/hero_vector_dollar.png" alt="Image"> -->
		<!-- <img decoding="async" src="assets/images/img/hero_vector_setting.png" alt="Image"> -->
		<!-- <img decoding="async" src="assets/images/img/hero_vector_arrow.png" alt="Image"> -->
	</div>
	<div class="container">
		<div class="row gy-5 gy-lg-0 align-items-center justify-content-between">
			<div class="col-12 col-lg-6">
				<div class="section__content">
					<span class="sub-title section__content-sub-title headingFour wow fadeInDown"
						data-wow-duration="0.8s"
						style="visibility: visible; animation-duration: 0.8s; animation-name: fadeInDown;">
						<img decoding="async" src="assets/images/img/title_vector.png" alt="Image">
						Welcome to Loan Onn</span>
					<h1 class="title section__content-title display-3 wow fadeInUp" data-wow-duration="0.8s"
						style="visibility: visible; animation-duration: 0.8s; animation-name: fadeInUp;"></h1>
					<h1 class="section__content-title display-3 wow fadeInUp" data-wow-duration="0.8s"
						style="visibility: visible; animation-duration: 0.8s; animation-name: fadeInUp;">Find the
						<span class="word d-inline-flex">
							<span data-wow-duration="0.6s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-name: fadeInDown;">P</span>
							<span data-wow-duration="0.6s" data-wow-delay="0.1s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.1s; animation-name: fadeInDown;">e</span>
							<span data-wow-duration="0.6s" data-wow-delay="0.2s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.2s; animation-name: fadeInDown;">r</span>
							<span data-wow-duration="0.6s" data-wow-delay="0.3s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.3s; animation-name: fadeInDown;">f</span>
							<span data-wow-duration="0.6s" data-wow-delay="0.4s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.4s; animation-name: fadeInDown;">e</span>
							<span data-wow-duration="0.6s" data-wow-delay="0.5s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.5s; animation-name: fadeInDown;">c</span>
							<span data-wow-duration="0.6s" data-wow-delay="0.6s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.6s; animation-name: fadeInDown;">t</span>
						</span>
						Loan for Your Needs
					</h1>
					<p class="xlr pp section__content-text wow fadeInDown" data-wow-duration="0.8s"
						style="visibility: visible; animation-duration: 0.8s; animation-name: fadeInDown;">Tailored
						Financial Products Just for You, with rewards<p>
					<div class="btn-group mt_40 wow fadeInUp" data-wow-duration="0.8s"
						style="visibility: hidden; animation-duration: 0.8s; animation-name: none;">
					</div>

					<div class="text-center d-flex justify-content-start w-100 grade">

						<span class="word d-inline-flex">
							<span data-wow-duration="0.6s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.6s; animation-name: fadeInDown;"><img
									src="assets/images/img/best-funding.png" class="img-fluid" alt=""></span>
							<!-- <span data-wow-duration="0.6s" data-wow-delay="0.9s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.8s; animation-delay: 0.7s; animation-name: fadeInDown;">
								<img src="assets/images/img/best-incla.png" class="img-fluid" alt="">
							</span> -->
							<span data-wow-duration="0.6s" data-wow-delay="1s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.8s; animation-name: fadeInDown;">
								<img src="assets/images/img/best-rate.png" class="img-fluid" alt="">
							</span>
							<span data-wow-duration="0.6s" data-wow-delay="1.3s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 1.3s; animation-delay: 1s; animation-name: fadeInDown;">
								<img src="assets/images/img/best-tenure.png" class="img-fluid" alt="">
							</span>
							<span data-wow-duration="0.6s" data-wow-delay="1.2s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 1s; animation-delay: 0.9s; animation-name: fadeInDown;">
								<img src="assets/images/img/best-service.png" class="img-fluid" alt="">
							</span>
							<span data-wow-duration="0.6s" data-wow-delay="1s" class="letter wow fadeInDown"
								style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.8s; animation-name: fadeInDown;">
								<img src="assets/images/img/best-rewards.png" class="img-fluid" alt="">
							</span>
							
							
						</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xxl-5">
				<div class="hero__thumb wow fadeInUp" data-wow-duration="0.8s"
					style="visibility: visible; animation-duration: 0.8s; animation-name: fadeInUp;">
					<div class="position-absolute hero__thumb_tree">
						<img decoding="async" src="assets/images/img/thumb_vector.png" alt="Image">
					</div>
					<div class="position-absolute hero__thumb_vector">
						<img decoding="async" src="assets/images/img/ruppes-banner.png" class="image-set"  alt="Image">
					</div>
					<img decoding="async" src="assets/images/img/hero_img.png" alt="Image" class="unset">
				</div>
			</div>
		</div>
	</div>
</section>



<!-- <section id="slider_area" class="slider_section">
		<div id="slider_id" class="slider_style_four">
			<div class="slider_content_wrap position-relative">
				<div class="background_overlay"></div>
				<div class="rx_slider_img img-zooming" data-background="assets/images/img/slider1.jpeg"></div>
				<div class="container">
					<div class="rx_slider_text_img_area position-relative">
						<div class="rx_slider_text headline pera-content"> 
							<span>Welcome to Loan Onn </span>
							<h1>For Your Infinite Dreams</h1>
							<p>Loans enable simply, leave everything to us, zero hassle.

								In</p>
							<a routerLink="contact">Contact Us</a>
						</div>
						
					</div>
				</div>
			</div>
			<div class="slider_content_wrap position-relative">
				<div class="background_overlay"></div>
				<div class="rx_slider_img img-zooming" data-background="/assets/images/img/slider2.jpeg"></div>
				<div class="container">
					<div class="rx_slider_text_img_area position-relative">
						<div class="rx_slider_text headline pera-content"> 
							<span>Welcome to Loan Onn </span>
							<h1>Tailored Financial Products Just for You, with Zero Service Fees</h1>
							<p>Expert Guidance for Your Financial Needs - Absolutely Free
							</p>
							<a routerLink="contact">Contact Us</a>
						</div>
						
					</div>
				</div>
			</div>
			<div class="slider_content_wrap position-relative">
				<div class="background_overlay"></div>
				<div class="rx_slider_img img-zooming" data-background="assets/images/img/slider3.jpg"></div>
				<div class="container">
				 
					<div class="rx_slider_text_img_area position-relative">
						<div class="rx_slider_text headline pera-content"> 
							<span>Welcome to Loan Onn </span>
							<h1>Smart Financial Choices Made Simple with Loan Onn</h1>
							<p>- No Fees, No Hassle</p>
							<a routerLink="contact">Contact Us</a>
						</div>
					
					</div>
				</div>
			</div>
		</div>
	</section> -->
<!-- End of slider section
	============================================= -->


<div class="container pt-5">
	<div class="rx-section-title headline text-center ">
		<h2>In Association With
			<span>Preferred RBI licensed Banks and NBFC</span>
		</h2>
	</div>

	<!-- <div id="inAssociate" class="owl-carousel owl-theme">
		<div class="item"> <img src="assets/images/img/bajaj-finserv.png" alt=""> </div>
		<div class="item"> <img src="assets/images/img/bank-of-baroda.png" alt=""> </div>
		<div class="item"> <img src="assets/images/img/chola.png" alt=""> </div>
		<div class="item"> <img src="assets/images/img/esaf.png" alt=""> </div>
		<div class="item"> <img src="assets/images/img/federal-bank.png" alt=""> </div>
		<div class="item"> <img src="assets/images/img/hdfc-bank.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/hdfc.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/idfc.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/indusland.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/kotak-1.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/magma.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/SBI.png" alt=""></div>
		<div class="item"> <img src="assets/images/img/yes-bank.png" alt=""></div>
	</div> -->
</div>
<!-- <div class="container pt-5">
		<div class="rx-section-title headline text-center">
			<h2 class="">Loan Onn <span>Reward</span>  Points System </h2> </div>
				<marquee behavior=""  class="pt-3 pb-3 marq" direction="float-left" scrollamount="5"> <a href="#">1 Loan Onn Coin = ₹ 1</a>&nbsp;&nbsp;&nbsp; <i aria-hidden="true" class="far fa-heart"></i> &nbsp;&nbsp;&nbsp;

					<a href="#"> ₹1 Lakh Home Loan = 25 Points</a> &nbsp;&nbsp;&nbsp;<i aria-hidden="true" class="far fa-heart"></i>&nbsp;&nbsp;&nbsp;
					
					<a href="#">₹1 lakh Loan Against Property = 50 Points</a> <i aria-hidden="true" class="far fa-heart"></i>&nbsp;&nbsp;&nbsp;
					
					<a href="#">₹1 lakh  Auto / Car Loan = 100 Points</a>&nbsp;&nbsp;&nbsp; <i aria-hidden="true" class="far fa-heart"></i>&nbsp;&nbsp;&nbsp;
					
					<a href="#">₹1 Lakh Personal Loan = 100 Points</a> &nbsp;&nbsp;&nbsp;<i aria-hidden="true" class="far fa-heart"></i>&nbsp;&nbsp;&nbsp;
					
					<a href="#">₹1000 Health Insurance  = 50 Points</a> &nbsp;&nbsp;&nbsp;<i aria-hidden="true" class="far fa-heart"></i>&nbsp;&nbsp;&nbsp;
					
					<a href="#">₹10000 Life Insurance = 100 Points  </a>&nbsp;&nbsp;&nbsp;
				</marquee>
		
	</div> -->

<!-- WHATS APP SECTION	 -->
<section class="rx-about-section pt-3 pb-4">
	<div class="container">
		<div class="rx-about-content">
			<div class="row">
				<div class="col-lg-12 wow fadeFromRight" data-wow-delay="100ms" data-wow-duration="1000ms">
					<div class="rx-about-text ">
						<div class="rx-section-title headline">
							<h2>Now getting a loans is just a text away
							</h2>
							<h4>Apply for loans on WhatsApp</h4>
						</div>
						<div class="rx-about-text-wrap ul-li-block pera-content">
							<div class="d-flex">
								<div>
									<ul>
										<li>No app download </li>
										<li> Minimum documents </li>
										<li> Just WhatsApp <b> <span class="badge bg-danger">"LOAN"</span>  </b> to <a href="https://wa.me/7403111222"><span class="badge bg-danger">7403 111222 </span> </a> </li>
									</ul>
								</div>
								<div>
									<span><img class="whataspp" src="/assets/images/theme-images/whatsapp.png"
											alt=""></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- WHATS APP SECTION	 -->
<section class="rx-about-section pt-1">
	<div class="container">
		<div class="rx-about-content">
			<div class="row align-items-center">
				<div class="col-lg-6 wow fadeFromLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
					<div class="rx-about-award-img position-relative">
						<div class="rx-about-img-wrap">
							<img src="/assets/images/img/w-01.png" alt="">
						</div>
						<!-- <div class="rx-about-award-text">
								<i class="fas fa-trophy"></i> <span>Experiences of 25+ years</span>
							</div> -->
					</div>
				</div>
				<div class="col-lg-6 wow fadeFromRight" data-wow-delay="100ms" data-wow-duration="1000ms">
					<div class="rx-about-text d-flex flex-column h-100 align-items-center">
						<div class="rx-section-title headline">
							<h2>Welcome to Loan Onn -
								<span>Your Financial Solutions Partner</span>
							</h2>
						</div>
						<div class="rx-about-text-wrap ul-li-block pera-content ">
							<p>LOAN ONN is  a platform that connects businesses with lending options offered by RBI-licensed BANKS AND NBFC partners.</p>
							<p>

								At Loan Onn, we pride ourselves on being your one-stop destination for all loans. Our mission is to help you compare and choose from a
								variety of financial products, tailored specifically to your needs. Our expert team
								conducts a comprehensive 360-degree analysis to provide you with the best-customized
								solutions. </p>
							<p>
								“Every customer at Loan Onn is assigned a dedicated Relationship Officer. This ensures
								that you receive personalized attention and support throughout your financial journey.”
							</p>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- End of about  section
	============================================= -->
<section class="pb-5">
	<div class="container">
		<div class="rx-section-title headline text-center">
			<!-- <h2>Services <span>We</span> Provide </h2> -->
		</div>
		<div class="row">
			<div class="col-md-4 col-sm-12 text-center simple">
				<img src="assets/images/img/time.png" class="img-fluid" alt="quick simple">
				<h3>Quick Simple</h3>
				<p>Minimum Documentation</p>
			</div>
			<div class="col-md-4 col-sm-12 text-center simple">
				<img src="assets/images/img/multiple.png" class="img-fluid" alt="multiple lenders">
				<h3>Multiple Lenders</h3>
				<p>Multiple lenders Increased chanses of loan approval</p>
			</div>
			<div class="col-md-4 col-sm-12 text-center simple">
				<img src="assets/images/img/best.png" class="img-fluid" alt="Best Fit">
				<h3>Best Fit</h3>
				<p> Specific loan products for specific needs </p>
			</div>
		</div>
	</div>
</section>
<!-- Start of practice area  section
	============================================= -->

<section class="rx-practice-section">
	<div class="container">
		<div class="rx-section-title headline text-center">
			<h2>Services
				<span>We</span> Provide
			</h2>
		</div>
		<div class="rx-practice-content">
			<div class="row">
				<div [routerLink]="['/login']" class="cursor-point col-lg-4 col-md-6 wow fadeFromUp"
					data-wow-delay="200ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Personal Loan</h3>

							<div class="rx-single">
								<ul>
									<li>Personal Loan</li>
									<li>Doctors</li>
									<li>Charter Accountant</li>
									<li>Cash Salary</li>
									<li>Small Ticket Loan</li>
									<li>Takeover lowest rate</li>
									<li>Top up</li>
								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-user"></i>
						</div>
					</div>

				</div>
				
				<div [routerLink]="['/login']" class="cursor-point col-lg-4 col-md-6 wow fadeFromUp"
					data-wow-delay="200ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Loan Against</h3>
							<div class="rx-single">
								<ul>
									<li>Commercial Building</li>
									<li>Residencial Property</li>
									<li>Plain Property</li>
									<li>Topup & Takeover Existing Loan</li>
								</ul>
							</div>
						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-user "></i>
						</div>
					</div>
				</div>
				<div [routerLink]="['/login']" class="cursor-point col-lg-4 col-md-6 wow fadeFromUp"
					data-wow-delay="0ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Business Loan</h3>
							<div class="rx-single">
								<ul>
									<li>Doctors</li>
									<!-- <li>SME Loan</li> -->
									<li>GST Loan</li>
									<li>Charterd Accountant</li>
									<li>GST Programe</li>
									<!-- <li>Income Programe</li> -->
									<li>Certificate Programe</li>
									<li>Small ticket loans</li>
								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fa fa-briefcase" aria-hidden="true"></i>

						</div>
					</div>

				</div>
				<div [routerLink]="['/login']" class="cursor-point col-lg-4 col-md-6 wow fadeFromUp"
					data-wow-delay="10ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Car Loan</h3>
							<div class="rx-single">
								<ul>
									<li>Used Car</li>
									<li>Refinance</li>
									<li>Topup</li>
									<li>Takeover (UP to 200%)</li>
								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-car"></i>
						</div>
					</div>

				</div>
				<div [routerLink]="['/login']" class="cursor-point col-lg-4 col-md-6 wow fadeFromUp"
					data-wow-delay="0ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Home Loan</h3>
							<div class="rx-single">
								<ul>
									<li>Home Construction</li>
									<li>Home Purchase</li>
									<li>Home Renovation</li>
									<li>Home Extension</li>
									<li>Takeover and top up</li>
									<li>Top up Loans</li>

								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-home"></i>
						</div>
					</div>

				</div>
				
				<div [routerLink]="['/login']" class="cursor-point col-lg-4 col-md-6 wow fadeFromUp"
					data-wow-delay="300ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Other Products</h3>
							<div class="rx-single">
								<ul>
									<li>Credit Card</li>
									<li>Over Draft</li>
									<li>Education Loan</li>
									<li>Gold Loan</li>
								</ul>
							</div>
						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-credit-card"></i>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>
<!-- End of practice area section
	============================================= -->
<!-- STAR -->
<!-- STAR -->
<!-- EMI CALCULATER AND FORM -->
<section class="rx-portfolio-secton">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-sm-12 pb-4">

				<div class="rx-section-title headline">
					<h2>EMI Calculator</h2>
				</div>

				<div class="card p-4">
					<div class="d-flex align-items-center">
						<h3> Loan Amount: (₹)</h3>
						<input type="number" class="form-control" placeholder="₹" [(ngModel)]="loanAmount" /><br />
					</div>
					<div class="d-flex align-items-center">
						<h3>Tenure in Months:</h3>
						<input type="number" class="form-control" placeholder="Month" [(ngModel)]="tenure" /> <br />
					</div>
					
					<div class="d-flex align-items-center">
						<h3>interest: (%)</h3>
						<input type="number" class="form-control" placeholder="%" [(ngModel)]="interest" />
					</div>

					<div class="d-flex align-items-center pt-3">
						<button class="btn btn-secondary me-4" (click)="cal()">Calculate</button>
						<h2 class="m-0 p-0">EMI:{{ emi }}/m INR (₹)</h2>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-sm-12 pb-4">
				<div class="rx-section-title headline">
					<h2>Request a Free Call Back </h2>
				</div>
				<div class="rx-contact-form me-0 card">
					<form #reqstForm="ngForm" (ngSubmit)="submitForm(reqstForm)">
						<input type="text" name="name" placeholder="Name" [(ngModel)]="userForm.name">
						<input type="email" name="email" placeholder="Mail Address" [(ngModel)]="userForm.email">
						<input type="phone" name="phone" placeholder="Phone Number" [(ngModel)]="userForm.number">
						<input type="text" name="regard" placeholder="Regarding" [(ngModel)]="userForm.regard">
						<button type="submit"><i class="fas fa-paper-plane"></i> Submit now</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- EMI CALCULATER AND FORM -->


<!-- Start of portfolio section
	============================================= -->
<!-- <section class="rx-portfolio-secton">
		<div class="rx-section-title headline text-center">
			<h2>We have shown Our Resent
				<span>Case Studies.</span>
			</h2>
		</div>
		<div class="rx-portfolio-content">
			<div id="portfolio-slide-id" class="rx-portfolio-slider">
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt1.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt2.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt3.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt4.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt5.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt6.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt7.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt8.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt9.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
				<div class="rx-portfolio-innerbox position-relative">
					<div class="rx-portfolio-border"></div>
					<div class="rx-portfolio-img position-relative">
						<img src="assets/images/theme-images/prt10.jpg" alt="">
					</div>
					<div class="rx-portfolio-text headline">
						<span class="text-uppercase"><a href="#">criminal case</a></span>
						<h3><a href="#">Donald Car Case</a></h3>
					</div>
					<div class="rx-port-icon text-center">
						<a href="!#"> <i class="fas fa-arrow-right"></i></a>
					</div>
				</div>
			</div>
		</div>
	</section>		 -->
<!-- End of portfolio section
	============================================= -->

<!-- Start of Counter section
	============================================= -->
<!-- <section class="rx-counter-section">
		<div class="background_parallax background_position relative-position" data-background="assets/img/law/slider/s1.jpg">
			<div class="background_overlay"></div>
			<div class="container"> 
				<div class="row">
					<div class="col-lg-3  col-sm-6">
						<div class="rx-counter-icon-text">
							<div class="rx-counter-icon float-left">
								<i class="far fa-thumbs-up"></i>
							</div>
							<div class="rx-counter-text pera-content">
								<span class="odometer" data-count="438">00</span>
								<p>Satisfied Customers</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="rx-counter-icon-text">
							<div class="rx-counter-icon float-left">
								<i class="far fa-comment"></i>
							</div>
							<div class="rx-counter-text pera-content">
								<span class="odometer" data-count="250">00</span>
								<p>Consulted</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="rx-counter-icon-text">
							<div class="rx-counter-icon float-left">
								<i class="far fa-lightbulb"></i>
							</div>
							<div class="rx-counter-text pera-content">
								<span class="odometer" data-count="1138">00</span>
								<p>Satisfied Customers</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="rx-counter-icon-text">
							<div class="rx-counter-icon float-left">
								<i class="fas fa-briefcase"></i>
							</div>
							<div class="rx-counter-text pera-content">
								<span class="odometer" data-count="38">00</span>
								<p>Satisfied Customers</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> -->
<!-- End of Counter section
	============================================= -->

<!-- Start of testimonial section
	============================================= -->
<!-- <section class="rx-testimonial-section">
		<div class="container">
			<div class="rx-section-title headline text-center">
				<h2>See What Out Happy
					<span>Clients</span> Says
				</h2>
			</div>
			<div class="rx-testimonial-content">
				<div id="rx-testimonial-slide-id" class="rx-testimonial-slider">
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="assets/images/theme-images/tst1.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Robert Frost</a></h4>
								<span>Managing Dirrector</span>
							</div>
							<p>Reach out not the long pole in my tent, but pull in ten extra bodies to help roll the tortoise, yet face time pipeline, for blue sky. creativity requires you to murder your children Cross sabers rehydrate.</p>
						</div>
					</div>
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="assets/images/theme-images/tst2.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Nobilt Gilt</a></h4>
								<span>Consulting Manager</span>
							</div>
							<p>
								This is our north star design at the end of the day, for digital literacy. Good optics prethink. Green technology and climate change products need full resourcing and support from a cross-functional</p>
						</div>
					</div>
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="assets/images/theme-images/tst1.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Robert Frost</a></h4>
								<span>SEO Expert</span>
							</div>
							<p>I recently worked with <span>easilon</span> for my home renovation project, and I couldn't be happier with the results. From the moment I walked into their showroom, I was impressed by the extensive selection</p>
						</div>
					</div>
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="images/tst2.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Nobilt Gilt</a></h4>
								<span>Consulting Manager</span>
							</div>
							<p>I have zero cycles for this first-order optimal strategies. Closing these latest prospects is like putting socks on an octopus out of scope, for we need evergreen content What's our go to market strategy.</p>
						</div>	
					</div>
				</div>
			</div>
		</div>
	</section>		 -->
<!-- End of testimonial section
	============================================= -->

<!-- Start of Footer section
	============================================= -->



<!-- Start of Feature  section
	============================================= -->
<section id="rx-feature" class="rx-feature-section position-relative mb-5">
	<div class="container">
		<div class="rx-feature-content">
			<div class="row justify-content-center">
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="0ms" data-wow-duration="1000ms">
					<div class="rx-feature-icon-text text-center">
						<div class="rx-feature-icon">
							<i class="flaticon-family"></i>
						</div>
						<div class="rx-feature-text headline pera-content">
							<h3><a>Dedicated Relationship Officers</a></h3>
							<p>Every customer at Loan Onn is assigned a dedicated Relationship Officer. This ensures
								that you receive personalized attention and support throughout your financial journey.
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="300ms" data-wow-duration="1000ms">
					<div class="rx-feature-icon-text text-center">
						<div class="rx-feature-icon">
							<i class="flaticon-gavel"></i>
						</div>
						<div class="rx-feature-text headline pera-content">
							<h3><a>Comprehensive After-Sales Support</a></h3>
							<p>We take pride in our unmatched after-sales support. From repayment schedules and tax
								certificates to claim processing and duplicate certificates, we handle it all.</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="600ms" data-wow-duration="1000ms">
					<div class="rx-feature-icon-text text-center">
						<div class="rx-feature-icon">
							<i class="flaticon-justice"></i>
						</div>
						<div class="rx-feature-text headline pera-content">
							<h3><a>Your Financial Success is Our Priority
								</a></h3>
								
							<p>At Loan Onn, we take pride in being your one-stop destination for all loan products. Our
								mission is to help you compare and choose from a variety of financial solutions,
								tailored specifically to your needs. Our expert team conducts a comprehensive 360-degree
								analysis to provide you with the best-customized solutions.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- End of Feature  section
	============================================= -->
<footer class="footer_section relative-position footer_section_4">
	<div class="background_overlay"></div>
	<div class="container">
		<div class="footer_content">
			<div class="row">
				<div class="col-lg-4 col-md-12">
					<div class="footer_widget headline">
						<h3 class="widget_title">
							About Us
						</h3>
						<div class="widget_footer_text">
							
							At Loan Onn, we pride ourselves on being your one-stop destination for all loans.
						</div>

					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="footer_widget headline">
						<h3 class="widget_title">
							Usefull Link

						</h3>
						<div class="practice_list ul-li-block clearfix">
							<ul>
								<li><a class="nav-link active" routerLink="/home">Home</a></li>
								<li><a class="nav-link" routerLink="/about">About</a></li>
								<li><a class="nav-link" routerLink="/service">Service</a></li>
								<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
								<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
								<li><a class="nav-link" routerLink="/contact">Contact</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="footer_widget headline">
						<h3 class="widget_title">
							Contact Us
						</h3>
						<div>
							<ul>
								<li class="d-flex">
									<i class="fas fa-map-marker-alt mr-2"></i>
									<a href="#"> Loanonn &#8482;<br> Infin Plus Pvt Ltd <br>
										27/12 J, 2nd Floor, A K S Tower
										Puthiyara Jn, Calicut
										Kerala – 673004 </a>
								</li>
								<li>
									<i class="fas fa-envelope mr-2"></i><a href="#">
										info&#64;investfinanceplus.com</a>
								</li>
								<li >
									<i class="fas fa-envelope mr-2"></i><a href="#">
										info&#64;loanonn.com</a>
								</li>
								<li>
									<i class="fas fa-phone mr-2"></i><a href="#">
										7403 111222</a>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

<div class="footer_copyright">
	<div class="container">
		<div class="footer_copyright_content">
			<div class="row">
				<div class="col-lg-4 col-md-12">
					<div class="footer_social ul-li clearfix">
						<ul>
							<li><a href="https://www.facebook.com/infinsmartadvise/"><i
										class="fab fa-facebook-f"></i></a></li>
							<li><a href="#"><i class="fab fa-twitter"></i></a></li>
							<li><a href="https://www.instagram.com/infinsmartadvise/"><i
										class="fab fa-instagram"></i></a></li>
							
							
						</ul>
					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="footer_logo text-center">
						<p class="text-white"><span>*</span>Terms & Conditions</p>
						<h6 class="text-white">The loans offered on the platform are subject to the terms and conditions and loan approval process of the  RBI-licensed BANKS AND NBFC partners</h6>
					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="copyright_text text-right">
						Copyright By <a href="https://hexabranding.com/">Hexa Branding</a> - 2024
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- End of Footer section
	============================================= -->

<button class="qrCode">
	<p class="p1">Scan Now</p>
	<img class="img-fluid p-1"  src="../../../assets/images/img/loanonnqr.png" alt="">
	<p class="p2">Get Reward</p>
</button>


<div class="whatasapp-btn"> <a href="https://wa.me/7403111222"><img src="/assets/images/theme-images/whatsapp.png" alt=""> </a> </div>