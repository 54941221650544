import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {


  // loanAmount: number = 200000;
  // // tenure: number = 10;
  // interest: number = 5;
  // emi: number = 0;
  // cal() {
  //   var outstandingAmount =
  //     Number(this.loanAmount) + Number(this.loanAmount * (this.interest / 100) * this.tenure);
  //     this.emi = outstandingAmount / this.tenure;
  // }

  loanAmount: number = 100000;
  tenure: number = 12; // In months
  interest: number = 10;
  emi: number = 0;

  isClassActive = false;  // Initially, the class is not active

  // Method to toggle the class
  toggleClass() {
    this.isClassActive = !this.isClassActive;
  }

  cal() {
    // Convert annual interest rate to a monthly rate
    let monthlyRate = this.interest / 12 / 100;

    // Calculate EMI
    let emi = this.loanAmount * monthlyRate * Math.pow(1 + monthlyRate, this.tenure) /
      (Math.pow(1 + monthlyRate, this.tenure) - 1);
      this.emi = Math.round(emi);
  }



  userForm = {
    name: '',
    email: '',
    number: '',
    regard: ''
  }

  submitForm(e: any) {
  }
}
